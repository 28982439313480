import "../App.css";
import { useState, useEffect, useRef } from "react";
import {
  FeedbackButton,
  FeedbackForm,
  GPTPanel,
  TranscriptPanel,
  ControlPanel,
  SummaryPanel,
  TranscriptSettingsPanel,
  ProfileButton,
  OssyText,
  LogInPanel,
  OssyLogo,
  AutoPausePanel,
  Header,
  OssyPlusPaywall,
  Button,
} from "../components";
import { normalizeAudio } from "../data/normalizeAudio";
import PostTranscriptFeedbackPanel from "../features/transcription/components/PostTranscriptFeedbackPanel";
import { useSelector, useDispatch } from "react-redux";
import { useLoaderData } from "react-router-dom";
import {
  resetPrevTranscriptType,
  handleResumeTranscript,
  addToTranscriptArray,
  handleStopTranscript,
  editTranscriptTitle,
  saveTranscriptAudio,
  resetUnfinishedText,
  logTranscriptSettings,
  logAutoPause,
  createSession,
  updateSessionEndDate,
  shouldDisplayPostTranscriptFeedbackPanel,
  handlePauseTranscript,
  fetchLiveTranscript,
  scrollToBookmark,
  fetchUserData,
  getWeekAudioDuration,
  checkIfUserUpdates,
  disconnectLiveTranscript,
  generateAITranscriptTitle,
  logAmplitudeEvent,
  saveContent,
  fetchWeekTranscriptionLimit,
} from "../transcriptSlice";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { slide as Menu } from "react-burger-menu";
import customVocabs from "../data/customVocabs.json";
import { onSignInGSI } from "../features/login/api/loginApi";
import {
  boxShadow,
  colors,
  formatTimestamp,
  GOOGLE_CLIENT_ID,
  logger,
  LogCategory,
  setScrollbarLightOrDarkMode,
  getIsMac,
  contentTypes,
  transcriptModeTypes,
  getIsNewTranscriptUrl,
  claimFreeHourStatus,
} from "../utility/utility.mjs";
import ShareButton from "../components/ShareButton";
import ProfessorOssyPanel from "../features/professorOssy/components/professorOssyPanel";
import actions from "../features/login/components/actions.json";
import CheckoutPanel from "../features/payment/components/CheckoutPanel";
import {
  BsChat,
  BsChatFill,
  BsPen,
  BsFileTextFill,
  BsPenFill,
  BsFileText,
  BsFileEarmarkPdf,
  BsFileEarmarkPdfFill,
} from "react-icons/bs";
import { RiVideoFill, RiVideoLine } from "react-icons/ri";
import { buttonStyles } from "../components/Button";
import { initialState } from "../data/initialState";
import SlidesPanel from "../features/slides/components/SlidesPanel";
import VideoPanel from "../features/video/components/VideoPanel";
import TabUnderline from "../components/TabUnderline";
import NotOwnerTooltip from "../components/NotOwnerTooltip";
import SharePanel from "../components/SharePanel";
import InviteThreeFriendsPanel from "../components/InviteThreeFriendsPanel";

// this needs to be defined outside of the function
// otherwise it keeps reading as empty
const audioBlobs = [];

const TranscriptPage = ({
  supabase,
  ownerOfTranscript,
  transcriptMode = transcriptModeTypes.LIVE_TRANSCRIPTION.id,
  dummy = false,
}) => {
  const [started, setStarted] = useState(false);
  const [language, setLanguage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [saveAudio, setSaveAudio] = useState(false);

  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
  const [autoPausePanel, setAutoPausePanel] = useState(false);
  const [highlightedSummaryBlurbIndex, setHighlightedSummaryBlurbIndex] =
    useState(-1);

  const [showTranscriptUpgradeButton, setShowTranscriptUpgradeButton] =
    useState(false);
  const dispatch = useDispatch();

  const contentType = contentTypes.TRANSCRIPT;

  const shareButtonRef = useRef(null);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const userId = useSelector((state) => state.routes.userId);
  const {
    title,
    contentArray,
    audioUrl,
    pageUrl,
    summarySentences,
    summaryBulletPoints,
    timestamps,
    isPrivate,
    displayPostTranscriptFeedbackPanel,
    id,
    embeddings,
    gptJsonArray,
    videoUrl,
  } = useSelector((state) => state.routes.transcript);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const justLoggedOut = useSelector((state) => state.routes.justLoggedOut);
  const audioUploading = useSelector((state) => state.routes.audioUploading);
  const audioUploadProgress = useSelector(
    (state) => state.routes.audioUploadProgress
  );
  const unlatexedIndex = useSelector((state) => state.routes.unlatexedIndex);

  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const weekAudioDuration = useSelector(
    (state) => state.routes.weekAudioDuration
  );

  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    dispatch(getWeekAudioDuration({ supabase }));
  }, [dispatch, supabase]);

  // Time of last updated transcript
  const timeOfLastUpdateTranscript = useRef(new Date());

  const [guestUserAction, setGuestUserAction] = useState(
    actions.START_LIVE_TRANSCRIPT.id
  );

  const [downloadOptionsVisible, setDownloadOptionsVisible] = useState(false);
  const [showSummaryPanel, setShowSummaryPanel] = useState(false);

  const [logInPanel, setLogInPanel] = useState(false);
  const [loading, setLoading] = useState(false);

  // feedback form
  const [feedbackForm, setFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  // const [showSharePanel, setShowSharePanel] = useState(false);
  const [displaySharePanel, setDisplaySharePanel] = useState(false);
  const transcriptObj = useSelector((state) => state.routes.transcript);
  const rightClickObject = {
    type: contentTypes.TRANSCRIPT,
    index: -1,
    thing: {
      ...transcriptObj,
      type: contentTypes.TRANSCRIPT,
    },
    active: false,
  };

  const [transcriptionSettings, setTranscriptionSettings] = useState(false);

  const transcriptAutoscroll = useRef(started);

  const [paused, setPaused] = useState(false);
  const [functionAfterLogin, setFunctionAfterLogIn] = useState(() => () => {});

  const [selectedTranscriptBlurbIndices, setSelectedTranscriptBlurbIndices] =
    useState([]);
  const uploadAudio = useSelector(
    (state) => state.routes.transcript.debugJson.uploadAudio
  );

  // resizing the window
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [panelCutoff, setPanelCutoff] = useState(
    window.matchMedia("(max-width: 650px)").matches
  );

  const [notOwnerPoints, setNotOwnerPoints] = useState({ x: 0, y: 0 });
  const [displayNotOwnerTooltip, setDisplayNotOwnerTooltip] = useState(false);
  const [notOwnerAction, setNotOwnerAction] = useState();

  const [showInviteFriendsPanel, setShowInviteFriendsPanel] = useState(false);

  // these panels should start off closed if the screen is small
  const [GPT, setGPT] = useState(!panelCutoff);
  const [chat, setChat] = useState(false);
  const [slides, setSlides] = useState(false);
  const [transcript, setTranscript] = useState(true);
  const [displayVideo, setDisplayVideo] = useState(false);

  const [menuWindowSize, setMenuWindowSize] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  const transcriptUrl = useLoaderData();
  // if a new transcript, determine the type of transcript, live, audio file, or youtube link
  useEffect(() => {
    if (getIsNewTranscriptUrl(window.location.pathname)) {
      setTranscriptionSettings(true);
      if (transcriptUrl === transcriptModeTypes.VIDEO_LINK.url) {
        closeAllPanelsExcept(PanelType.Video);
      }
    }
  }, [transcriptUrl]);

  useEffect(() => {
    logger([LogCategory.DEBUG], `transcript privacy: ${isPrivate}`);
    // new transcript, no need to do anything
    if (getIsNewTranscriptUrl(window.location.pathname)) {
      return;
    }

    // user arrived at this page logged out and it's not a new transcript, make them sign in to see
    else if (!isLoggedIn) {
      setGuestUserAction(actions.VIEW_TRANSCRIPT_ATTEMPT);
      setLogInPanel(true);
    }
  }, [isLoggedIn, justLoggedOut]);

  // extracts the blurbIndex from url parameters and scrolls to the corresponding blurb
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const formattedTimeInTranscript = searchParams.get("t");

    // url does not have a timestamp for us to jump to
    if (formattedTimeInTranscript === null) return;

    // get the index of the timestamp in the url
    const timestampIndex = timestamps.findIndex(
      (ts) => formatTimestamp(ts) === formattedTimeInTranscript
    );

    // wasn't able to find the timestamp for whatever reason (might be invalid)
    if (timestampIndex < 0) return;

    // scroll to the transcript blurb & explanation blurb
    dispatch(
      scrollToBookmark({
        blurbIndex: timestampIndex,
        setHighlightedSummaryBlurbIndex,
        setHighlightedTranscriptBlurbIndex,
        highlightSummaryBlurbTimeout,
        highlightTranscriptBlurbTimeout,
      })
    );

    // jump to this point in the audio
    const audio = document.getElementById("audio");
    if (audio !== null) {
      audio.currentTime = Number(timestamps[timestampIndex]);
    }
  }, [timestamps, dispatch]);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia("(max-width: 768px)").matches);
      setMenuWindowSize(window.matchMedia("(max-width: 1000px)").matches);

      const newPanelCutoff = window.matchMedia("(max-width: 650px)").matches;
      setPanelCutoff(newPanelCutoff);
    };
    window.addEventListener("resize", handleResize, {
      passive: true,
    });

    return () => {
      window.removeEventListener("resize", handleResize, {
        passive: true,
      });
    };
  }, []);

  useEffect(() => {
    dispatch(fetchWeekTranscriptionLimit({ supabase }));
  }, []);

  useEffect(() => {
    // 1 panel: when going to one panel display the current left panel
    if (panelCutoff) {
      if (displayVideo) {
        closeAllPanelsExcept(PanelType.Video);
      }
      if (transcript) {
        closeAllPanelsExcept(PanelType.Transcript);
      }
    }
    // 2 panels
    if (!panelCutoff) {
      if (!displayVideo && !transcript) {
        closeAllPanelsExcept(PanelType.Transcript);
      }

      if (!GPT && !chat && !slides) {
        closeAllPanelsExcept(PanelType.GPT);
      }
    }
  }, [panelCutoff]);

  const titleRef = useRef(null);
  const [displayedTranscriptTitle, setDisplayedTranscriptTitle] =
    useState(title);

  // update displayed transcriptTile if title changes and is currently not the same
  useEffect(() => {
    if (title !== displayedTranscriptTitle) {
      setDisplayedTranscriptTitle(title);
    }
  }, [title]);

  const hasFetchedTranscriptTitle = useRef(false);
  // generate an AI title based on the transcript after 1 minute if untitled
  useEffect(() => {
    const lastTimestamp = timestamps[unlatexedIndex];
    if (
      hasFetchedTranscriptTitle.current === false &&
      title === initialState.transcript.title &&
      lastTimestamp > 60
    ) {
      hasFetchedTranscriptTitle.current = true;
      dispatch(
        generateAITranscriptTitle({
          supabase,
          titleRef,
          setDisplayedTranscriptTitle: setDisplayedTranscriptTitle,
        })
      );
    }
  }, [unlatexedIndex]);

  const mediaRecorder = useRef(null);
  const websocket = useRef(null);
  const mediaStream = useRef(null);

  const [customVocab, setCustomVocab] = useState("Choose Subject");
  const [filterProfanity, setFilterProfanity] = useState(false);
  const [filterDisfluencies, setFilterDisfluencies] = useState(false);

  const [highlightedTranscriptBlurbIndex, setHighlightedTranscriptBlurbIndex] =
    useState(-1);
  const highlightTranscriptBlurbTimeout = useRef(null);
  const highlightSummaryBlurbTimeout = useRef(null);

  const transcriptIndexToSummaryIndex = {};

  const [showUpgradePanel, setShowUpgradePanel] = useState(false);

  let prevWordsLength = 0;
  let counter = 1;
  let summaryIndex_ = 0;

  // Controls when to stop screen from sleeping/dimming
  const wakeLock = useRef(null);

  /*
    Assign each text blurb from the transcription to a corresponding summary blurb.
    Each summary blurb corresponds to max(50 words, len(one text blurb))
  */
  contentArray.forEach((text, index) => {
    // assign the transcript index a summary index
    transcriptIndexToSummaryIndex[index] = summaryIndex_;
    const words = (text ?? "").split(" ");
    const newTextLength = prevWordsLength + words.length;
    // increase the summary index if we just passed another 50 words
    if (newTextLength > 50 * counter) {
      summaryIndex_ += 1;
    }
    // if one blurb is somehow longer than 50 words, increase the counter
    counter += Math.floor((newTextLength - 50 * counter) / 50) + 1;
    // update length of previous words
    prevWordsLength += words.length;
  });

  const trptRef = useRef(null);

  const headerHeight = 80;

  // the bottom should have more height when it's also displaying the audio seekbar
  const bottomHeight =
    !started && contentArray.length !== 0 && audioUrl !== "" ? 150 : 100;

  // convert all the latex on initial page load,
  // when exiting edit mode, and when more audio is transcribed
  useEffect(() => {
    if (editMode) {
      return;
    }

    try {
      if (window.MathJax) {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    } catch (e) {
      console.error(`couldn't display latex: ${e}`);
      try {
        if (window.MathJax) {
          window.MathJax.typesetClear();
          window.MathJax.typeset();
        }
      } catch (e2) {
        console.error(`failed again at parsing latex: ${e2}`);
      }
    }

    return () => window.MathJax.typesetClear();
  }, [
    editMode,
    contentArray,
    summarySentences,
    summaryBulletPoints,
    showSummaryPanel,
    gptJsonArray,

    // parse any latex in the professor ossy chat
    chat,
  ]);

  // we have to change the color of the latex to white to see it in dark mode
  useEffect(() => {
    let styleElement = document.createElement("style");
    styleElement.innerHTML = `mjx-container {color: ${
      darkMode ? "white" : "black"
    };}`;

    document.head.appendChild(styleElement);
  }, [darkMode]);

  // add supabase listener for live shared transcripts
  useEffect(() => {
    if (!ownerOfTranscript) {
      logger([LogCategory.DEBUG], "fetching live transcript data");
      // immediately save the transcript if you are not the owner of the transcript
      dispatch(saveContent({ supabase, contentId: id, contentType }));
      dispatch(fetchLiveTranscript({ supabase, id }));
    } else if (ownerOfTranscript && !isPaidUser) {
      dispatch(checkIfUserUpdates({ supabase }));
    }

    return () => dispatch(disconnectLiveTranscript({ supabase }));
  }, []);

  useEffect(() => {
    // logger("about to start generating embeddings for top users...");
    // dispatch(generateTranscriptEmbeddingsForTopUsers({ supabase }));
  }, [dispatch, supabase]);

  // find out if they have access to professor ossy or not
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserData({ supabase }));
    }
  }, [dispatch, isLoggedIn, supabase]);

  // Prevent screen from sleeping/dimming when you start recording
  useEffect(() => {
    if (
      document.visibilityState === "visible" &&
      started &&
      !paused &&
      navigator.wakeLock
    ) {
      navigator.wakeLock.request("screen").then((lock) => {
        wakeLock.current = lock;
      });
    }

    // Release wake lock
    return () => {
      if (wakeLock.current) {
        wakeLock.current.release().then(() => {
          wakeLock.current = null;
        });
      }
    };
  }, [paused, started]);

  // Create or update transcript session data
  useEffect(() => {
    if (isLoggedIn && pageUrl !== null) {
      // every minute, log the end of the session
      const logSessionInterval = setInterval(() => {
        dispatch(updateSessionEndDate({ supabase }));
      }, 60000);

      return () => clearInterval(logSessionInterval);
    }
  }, [dispatch, isLoggedIn, supabase, pageUrl]);

  // warn user if they try to reload the page while transcribing
  useEffect(() => {
    const preventUnload = (e) => {
      if (started) {
        e.preventDefault();
        e.returnValue =
          "Are you sure? Reloading this page will end your transcription.";
      }
    };

    if (started) {
      window.addEventListener("beforeunload", preventUnload);
    } else {
      window.removeEventListener("beforeunload", preventUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, [started]);

  let supportedMimeType;
  if (MediaRecorder.isTypeSupported("audio/ogg")) {
    supportedMimeType = "audio/ogg";
  } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
    supportedMimeType = "audio/mp4";
  }

  const connectToRevAPI = async (mediaStream_) => {
    const access_token = process.env.REACT_APP_REV_API_ACCESS_TOKEN;
    const websocketURL = `wss://api.rev.ai/speechtotext`;

    // streaming audio to Rev.AI API
    let websocketURI = `${websocketURL}/v1/stream?access_token=${access_token}&content_type=audio/flac`;

    // I only have custom vocabs for english + computer science right now
    if (customVocab && ["en", false].includes(language)) {
      if (customVocab in customVocabs) {
        websocketURI += `&custom_vocabulary_id=${customVocabs[customVocab]}`;
      } else {
        websocketURI += `&custom_vocabulary_id=${customVocabs["Other"]}`;
      }
    }

    if (filterProfanity) {
      websocketURI += "&filter_profanity=true";
    }

    if (filterDisfluencies) {
      websocketURI += "&remove_disfluencies=true";
    }

    if (language) {
      websocketURI += `&language=${language}`;
    }
    logger([LogCategory.DEBUG], "websocketURI: " + websocketURI);

    // connect to Rev.AI API for transcription
    websocket.current = new WebSocket(websocketURI);

    // when we first connect to Rev API...
    websocket.current.onopen = async (event) => {
      dispatch(resetPrevTranscriptType({}));

      timeOfLastUpdateTranscript.current = new Date();

      // use MediaStream Recording API to get audio data
      mediaRecorder.current = new MediaRecorder(mediaStream_, {
        audioBitsPerSecond: 128000,
        mimeType: supportedMimeType,
      });

      // received some audio from microphone
      mediaRecorder.current.ondataavailable = (event) => {
        // get the audio
        let blob = event.data;
        blob = normalizeAudio(event.data);

        if (saveAudio) {
          audioBlobs.push(blob);
        }

        if (
          websocket.current &&
          websocket.current.readyState === websocket.current.OPEN
        ) {
          websocket.current.send(new Blob([blob], { type: "audio/flac" }));
        }
      };

      mediaRecorder.current.onstop = (event) => {};

      mediaRecorder.current.onstart = () => {
        setStarted(true);
        transcriptAutoscroll.current = true;
        setPaused(false);
        setLoading(false);
      };

      // make data available event fire every 200 ms
      mediaRecorder.current.start(200);
    };

    // when we close the connection to Rev.AI API...
    websocket.current.onclose = (event) => {
      // there's some sort of problem
      if (event.code === 4002) {
        logger([LogCategory.ERROR], "websocket closing with error");
        // pause transcribing & recording
        setPaused(true);

        // disconnecting the websocket since I get charged for it
        if (mediaRecorder.current) {
          mediaRecorder.current.stop();
        }
        // if (mediaStream.current) {
        mediaStream_.current.getTracks().forEach((mediaTrack) => {
          mediaTrack.stop();
        });
        // }
        mediaRecorder.current = null;
        websocket.current = null;
        mediaStream.current = null;
      }
    };

    // when we receive a message from the Rev.AI API...
    websocket.current.onmessage = async (event) => {
      const results = JSON.parse(event.data);
      // update the state with the new data
      if (["partial", "final"].includes(results.type)) {
        timeOfLastUpdateTranscript.current = new Date();
        dispatch(addToTranscriptArray({ supabase, results })).unwrap();
      }
    };

    // when we receive an error from the Rev.AI API...
    websocket.current.onerror = (event) => {
      logger(
        [LogCategory.ERROR],
        "got an error: " + JSON.stringify(event) + "; event: " + event
      );
    };
  };

  const errorAccessingMicrophone = (error) => {
    setStarted(false);
    if (error.name === "NotAllowedError") {
      alert(
        "In order for Ossy to transcribe audio, you have to grant microphone access."
      );
    } else {
      alert("Error accessing microphone, please try again.");
    }
    setLoading(false);
  };

  const startTranscribing = async () => {
    // we need to store some information otherwise
    // if things go wrong it's excruciatingly hard to debug
    dispatch(
      logTranscriptSettings({
        customVocab: customVocab,
        filterProfanity: filterProfanity,
        filterDisfluencies: filterDisfluencies,
        saveAudio: saveAudio,
        supabase: supabase,
      })
    );

    // show loading until we've connected to the transcription API
    // and obtained browser permission to start recording audio
    setLoading(true);

    // reset marker for keeping track of transcript results that
    // didn't end with proper punctuation
    dispatch(resetUnfinishedText({}));

    // request permission to access microphone
    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      connectToRevAPI(mediaStream.current);
    } catch (error) {
      errorAccessingMicrophone(error);
    }
  };

  const resumeTranscribing = () => {
    dispatch(handleResumeTranscript({}));

    // Set time of last update transcript to current time so auto pause feature resets
    timeOfLastUpdateTranscript.current = new Date();

    // show loading until we've connected to the transcription API
    // and obtained browser permission to start recording audio
    setLoading(true);
    setPaused(false);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(connectToRevAPI, errorAccessingMicrophone);
    setLoading(false);
  };

  const pauseTranscribing = () => {
    // pause transcribing & recording
    setPaused(true);
    dispatch(handlePauseTranscript({ supabase }));

    // disconnecting the websocket since I get charged for it
    if (websocket.current !== null) {
      websocket.current.close();
    }
    if (mediaRecorder.current !== null) {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current !== null) {
      mediaStream.current.getTracks().forEach((mediaTrack) => {
        mediaTrack.stop();
      });
    }

    mediaRecorder.current = null;
    websocket.current = null;
    mediaStream.current = null;
  };

  // Pause transciption when the transcript has not updated in a while, meaning no one is talking
  useEffect(() => {
    // logger([LogCategory.DEBUG], "entered the auto-pause useEffect");
    let intervalId;
    // Start interval if recorder started and not paused
    if (started && !paused) {
      // amount of time with no new transcript results before pausing transcript
      const TIME_DIFFERENCE_CUTTOFF = 600;

      // Interval for verifying whether to pause the transcript
      const AUTO_PAUSE_CHECK_INTERVAL_SECS = 5;
      const PAUSE_TRANSCRIPT_INTERVAL_IN_MILLISECONDS =
        AUTO_PAUSE_CHECK_INTERVAL_SECS * 1000;

      // At each interval, assess the timestamp of the last transcription update;
      // if it occurred too far in the past, pause the transcription.
      intervalId = setInterval(() => {
        const currentTime = new Date();
        const timeDifferenceInSeconds =
          (currentTime.getTime() -
            timeOfLastUpdateTranscript.current.getTime()) /
          1000;
        if (timeDifferenceInSeconds >= TIME_DIFFERENCE_CUTTOFF) {
          pauseTranscribing();
          setAutoPausePanel(true);
          logger([LogCategory.DEBUG], "just paused it");
          dispatch(logAutoPause({ supabase }));
        }
      }, PAUSE_TRANSCRIPT_INTERVAL_IN_MILLISECONDS);
    }
    // Stop interval if paused
    if (paused || !started) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [started, paused, dispatch, supabase]);

  const stopTranscribing = () => {
    dispatch(handleStopTranscript({ supabase }));

    // pause transcribing & recording
    setPaused(true);

    // disconnecting the websocket since I get charged for it
    if (websocket.current) {
      websocket.current.close();
    }

    // we need to set started to false so it knows to show the audio player
    setStarted(false);

    // save the transcript done event in amplitude
    const eventName = "Transcript Done";
    const transcriptLengthInSeconds = timestamps.slice(-1);
    const transcriptLengthInMinutes = transcriptLengthInSeconds / 60;
    const eventProperties = {
      "transcriptLength (min.)": transcriptLengthInMinutes,
      id: id,
    };
    dispatch(logAmplitudeEvent({ eventName, eventProperties }));

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((mediaTrack) => {
        mediaTrack.stop();
      });
    }

    mediaRecorder.current = null;
    websocket.current = null;
    mediaStream.current = null;

    // only save the audio if the user opted in to save the audio
    if (saveAudio) {
      dispatch(
        saveTranscriptAudio({
          supabase: supabase,
          audioBlobs: audioBlobs,
          fileType: supportedMimeType,
        })
      );
      setSaveAudio(false);
    } else {
      // always display the post transcript feedback panel when you click done
      dispatch(
        shouldDisplayPostTranscriptFeedbackPanel({ supabase: supabase })
      );
    }
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  // keyboard shortcuts for ossy
  useEffect(() => {
    const handleKeyUp = (event) => {
      // ignore key presses if user is editing a transcription blurb
      if (
        feedbackForm ||
        document.activeElement.classList.contains("transcriptBlurbText") ||
        document.activeElement.id === "title_box"
      ) {
        return;
      }

      if (
        ["input", "select", "button", "textarea"].includes(
          document.activeElement.tagName.toLowerCase()
        ) ||
        document.activeElement.isContentEditable
      ) {
        return;
      }

      // ignore key presses if user is typing feedback
      if (feedbackForm) {
        return;
      }

      // toggle pause/play audio on spacebar press
      if (!started && [" "].includes(event.key)) {
        const audio = document.getElementById("audio");
        if (!audio) {
          return;
        }

        logger("how are we getting here?");
        if (audio.paused) {
          audio.play();
        } else {
          audio.pause();
        }
      }

      // exit edit mode when enter or escape is pressed
      if (["Enter", "Escape"].includes(event.key) && editMode) {
        logger([LogCategory.DEBUG], "exiting edit mode");
        setEditMode(false);
      }

      // ctrl + e should enter/edit edit mode
      if (["E", "e"].includes(event.key) && event.ctrlKey) {
        setEditMode(!editMode);
      }

      // ctrl + a should enter/edit edit mode
      if (["A", "a"].includes(event.key) && event.ctrlKey) {
        setGPT(!GPT);
      }

      // ctrl + s should enter/edit edit mode
      if (["S", "s"].includes(event.key) && event.ctrlKey) {
        setShowSummaryPanel(!showSummaryPanel);
      }

      // ctrl + d should enter/edit edit mode
      if (["D", "d"].includes(event.key) && event.ctrlKey) {
        setDownloadOptionsVisible(!downloadOptionsVisible);
      }
    };

    document.addEventListener("keyup", handleKeyUp, { passive: true });
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("keyup", handleKeyUp, { passive: true });
    };
  }, [
    started,
    editMode,
    GPT,
    showSummaryPanel,
    downloadOptionsVisible,
    feedbackForm,
  ]);

  const smallWindowMenu = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      right: 16,
      top: "26px",
    },

    // the three horizontal bars
    bmBurgerBars: {
      borderRadius: 10,
      backgroundColor: "gray",
      background: "gray",
    },

    // three horizontal bars on hover
    bmBurgerBarsHover: {
      background: "lightgray",
      display: "none",
      backgroundColor: "lightgray",
      // background: "lightgray",
    },

    bmCrossButton: {
      height: "24px",
      width: "24px",
    },

    // the x to close it
    bmCross: {
      backgroundColor: "white",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },

    // the menu itself
    bmMenu: {
      background: "rgba(0, 0, 0, 0.5)",
      paddingTop: "2em",
      // backgroundColor: "white",
      fontSize: "1.15em",
      flex: 1,
      // left: 0,
      // position: "absolute",
      // position: "absolute",
      // left: 0,
    },

    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      width: "100%",
      margin: 0,
    },

    // container for items in menu
    bmItem: {
      display: "flex",
      padding: 5,
      fontSize: 20,
      width: "100%",
    },

    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      width: "100%",
      height: "100%",
    },
  };

  const PanelType = {
    GPT: "GPT",
    Chatbot: "Chatbot",
    Transcript: "Transcript",
    Slides: "Slides",
    Video: "Video",
  };

  const closeAllPanelsExcept = (openPanel) => {
    // right panel
    if (
      panelCutoff ||
      [PanelType.GPT, PanelType.Chatbot, PanelType.Slides].includes(openPanel)
    ) {
      // close all panels except openPanel
      if (openPanel !== PanelType.GPT) setGPT(false);
      if (openPanel !== PanelType.Chatbot) setChat(false);
      if (openPanel !== PanelType.Slides) setSlides(false);

      // open the panel corresponding to openPanel
      if (openPanel === PanelType.GPT) setGPT(true);
      if (openPanel === PanelType.Chatbot) setChat(true);
      if (openPanel === PanelType.Slides) setSlides(true);
    }

    // left panel
    if (
      panelCutoff ||
      [PanelType.Transcript, PanelType.Video].includes(openPanel)
    ) {
      // close all panels except openPanel
      if (openPanel !== PanelType.Video) setDisplayVideo(false);
      if (openPanel !== PanelType.Transcript) setTranscript(false);

      // open the panel corresponding to openPanel
      if (openPanel === PanelType.Video) setDisplayVideo(true);
      if (openPanel === PanelType.Transcript) setTranscript(true);
    }
  };

  return (
    <>
      <div>
        {/* right menu when screen is small */}
        {!isLoggedIn && menuWindowSize && (
          <Menu styles={smallWindowMenu} width={250} right>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    onSignInGSI(credentialResponse, dispatch, supabase);

                    // start a session becuase user just logged in on transcript page
                    dispatch(createSession({ supabase }));
                  }}
                  onError={() => {
                    logger([LogCategory.DEBUG], "Login Failed");
                  }}
                  // useOneTap
                  auto_select
                  width={200}
                  shape="square"
                />
              </GoogleOAuthProvider>
            </div>
            {smallWindow && (
              <FeedbackButton setFeedbackForm={setFeedbackForm} />
            )}
          </Menu>
        )}

        <div
          className="App"
          style={{
            backgroundColor: darkMode ? colors.black : "white",
          }}
        >
          <div
            style={{
              width: "90%",
              maxWidth: "min(1500px, 90%)",
              height: headerHeight + 4,
            }}
          >
            <Header
              marginInside={0}
              left={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 12,
                    fontWeight: 900,
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <OssyLogo width={25} marginRight={4} />
                  {!panelCutoff && <OssyText big />}
                </div>
              }
              center={
                <div
                  style={{
                    borderRadius: 6,
                    width: "90%",
                    maxWidth: 800,
                    margin: "auto",
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: 35,
                    minHeight: 35,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {displaySharePanel && (
                    <SharePanel
                      setDisplaySharePanel={setDisplaySharePanel}
                      supabase={supabase}
                      isOwnerOfContent={ownerOfTranscript}
                      rightClickObject={rightClickObject}
                    />
                  )}

                  <ShareButton
                    setDisplaySharePanel={setDisplaySharePanel}
                    shareButtonRef={shareButtonRef}
                    contentType={contentType}
                  />
                  <input
                    ref={titleRef}
                    type="text"
                    name="name"
                    autoComplete="off"
                    id="title_box"
                    defaultValue={title}
                    value={displayedTranscriptTitle}
                    // you can't rename a transcript you don't own
                    onKeyDown={(event) => {
                      if (["Enter", "Escape"].includes(event.key)) {
                        event.currentTarget.blur();
                      }
                    }}
                    onChange={(event) => {
                      if (ownerOfTranscript) {
                        const newTranscriptTitle = event.target.value;
                        setDisplayedTranscriptTitle(newTranscriptTitle);
                      } else {
                        setNotOwnerPoints({ x: event.pageX, y: 50 });
                        setNotOwnerAction("edit the title");
                        setDisplayNotOwnerTooltip(true);
                      }
                    }}
                    onBlur={(e) => {
                      // edit transcript title if logged in
                      if (isLoggedIn) {
                        if (
                          e.currentTarget.value !== "" &&
                          e.currentTarget.value !== title
                        ) {
                          logger(
                            [LogCategory.DEBUG],
                            `pushing new title to database: ${e.currentTarget.value}`
                          );
                          dispatch(
                            editTranscriptTitle({
                              newTitle: e.currentTarget.value,
                              supabase: supabase,
                            })
                          );
                        }
                        // otherwise show log in panel
                      } else if (e.currentTarget.value !== "") {
                        setGuestUserAction(actions.TITLE_TRANSCRIPT_ATTEMPT);
                        e.currentTarget.value = title;
                        setLogInPanel(true);
                      }
                    }}
                    style={{
                      // border: "none",
                      fontWeight: 700,
                      fontSize: 20,
                      backgroundColor: darkMode
                        ? colors.gray1
                        : colors.lightGray,
                      color: darkMode ? "white" : "black",
                      alignSelf: "center",
                      textAlign: "center",
                      // minWidth: 300,

                      // safari gives the input this ugly margin
                      margin: 0,

                      // safari gives the input this ugly weird border
                      border: 0,
                      justifySelf: "center",
                      overflowX: "hidden",
                      flexGrow: 1,
                      borderTopRightRadius: 6,
                      borderBottomRightRadius: 6,
                      maxHeight: 35,
                      minHeight: 35,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 0,
                      paddingBottom: 0,
                      // flex: 0.5,
                    }}
                  />
                </div>
              }
              right={
                <div
                  style={{
                    color: "black",
                    // alignSelf: "center",
                    alignItems: "center",
                    fontSize: 12,
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  {/* google sign in */}
                  {!menuWindowSize && !isLoggedIn && (
                    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          onSignInGSI(credentialResponse, dispatch, supabase);
                        }}
                        onError={() => {
                          logger([LogCategory.ERROR], "Login Failed");
                        }}
                        // useOneTap
                        auto_select
                        width={200}
                        shape="square"
                      />
                    </GoogleOAuthProvider>
                  )}

                  {/* profile options in top right */}
                  {isLoggedIn && (
                    <ProfileButton removeNameWhenSmall={768} left />
                  )}
                </div>
              }
            />
          </div>

          {/* panels */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              flexGrow: 1,
              // maxHeight: `calc(100dvh - ${headerHeight + bottomHeight}px)`,
              overflow: "hidden",
              width: "90%",
              maxWidth: "min(1500px, 90%)",
              justifyContent: "center",
              gap: 12,
            }}
          >
            {/* transcript panel holder */}
            <div
              style={{
                flex: 1,
                flexGrow: 1,
                position: "static",
                display: "flex",
                flexDirection: "column",
                marginBottom: 16,
                maxWidth: "min(1500px, 100%)",
                width: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  overflowX: "clip",
                }}
              >
                {/* Transcript button */}

                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    marginBottom: 8,
                    width: "100%",
                    borderBottomColor: darkMode ? colors.gray1 : colors.gray,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                  }}
                >
                  <TabUnderline active={transcript}>
                    <Button
                      style={{
                        overflow: "clip",
                        flexGrow: transcript ? 1 : 0,
                        flexShrink: transcript ? 0 : 1,
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                            overflow: "clip",
                          }}
                        >
                          {transcript ? (
                            <BsPenFill style={{ minWidth: 14, maxWidth: 14 }} />
                          ) : (
                            <BsPen style={{ minWidth: 14, maxWidth: 14 }} />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Transcript
                          </p>
                        </div>
                      }
                      isClickable={!transcript}
                      onClick={(e) => {
                        closeAllPanelsExcept(PanelType.Transcript);
                      }}
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                    />
                  </TabUnderline>

                  {(videoUrl !== initialState.transcript.videoUrl ||
                    transcriptMode === transcriptModeTypes.VIDEO_LINK.id) && (
                    <TabUnderline active={displayVideo}>
                      <Button
                        style={{
                          overflow: "clip",
                          flexGrow: displayVideo ? 1 : 0,
                          flexShrink: displayVideo ? 0 : 1,
                        }}
                        content={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "center",
                              overflow: "clip",
                            }}
                          >
                            {displayVideo ? (
                              <RiVideoFill
                                style={{ minWidth: 14, maxWidth: 14 }}
                              />
                            ) : (
                              <RiVideoLine
                                style={{ minWidth: 14, maxWidth: 14 }}
                              />
                            )}
                            <p
                              style={{
                                overflow: "clip",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              Video
                            </p>
                          </div>
                        }
                        isClickable={!displayVideo}
                        onClick={(e) => {
                          closeAllPanelsExcept(PanelType.Video);
                        }}
                        buttonStyle={
                          darkMode
                            ? buttonStyles.panelTabsDarkMode
                            : buttonStyles.panelTabs
                        }
                      />
                    </TabUnderline>
                  )}

                  {panelCutoff && (
                    <>
                      {/* AI summaries button */}
                      <TabUnderline active={GPT}>
                        <Button
                          style={{
                            overflow: "clip",
                            flexGrow: GPT ? 1 : 0,
                            flexShrink: GPT ? 0 : 1,
                          }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 4,
                                alignItems: "center",
                                overflow: "clip",
                              }}
                            >
                              {GPT ? (
                                <BsFileTextFill
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              ) : (
                                <BsFileText
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              )}
                              <p
                                style={{
                                  overflow: "clip",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                AI Summaries
                              </p>
                            </div>
                          }
                          onClick={() => {
                            closeAllPanelsExcept(PanelType.GPT);
                          }}
                          isClickable={
                            //   [
                            //   CURRENT_LIVE_TRANSCRIPT,
                            //   COMPLETED_OR_UPLOADING_AUDIO,
                            // ].includes(state)
                            !GPT
                          }
                          buttonStyle={
                            darkMode
                              ? buttonStyles.panelTabsDarkMode
                              : buttonStyles.panelTabs
                          }
                        />
                      </TabUnderline>

                      {/* Professor Ossy button */}
                      <TabUnderline active={chat}>
                        <Button
                          style={{
                            overflow: "clip",
                            flexGrow: chat ? 1 : 0,
                            flexShrink: chat ? 0 : 1,
                          }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 4,
                                alignItems: "center",
                                overflow: "clip",
                              }}
                            >
                              {chat ? (
                                <BsChatFill
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              ) : (
                                <BsChat
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              )}
                              <p
                                style={{
                                  overflow: "clip",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Chatbot (Professor Ossy)
                              </p>
                            </div>
                          }
                          isClickable={
                            !chat
                            // || [COMPLETED_OR_UPLOADING_AUDIO, CURRENT_LIVE_TRANSCRIPT].includes(
                            //   state
                            // )
                          }
                          onClick={() => {
                            closeAllPanelsExcept(PanelType.Chatbot);
                          }}
                          buttonStyle={
                            darkMode
                              ? buttonStyles.panelTabsDarkMode
                              : buttonStyles.panelTabs
                          }
                        />
                      </TabUnderline>

                      {/* Slides button */}
                      <TabUnderline active={slides}>
                        <Button
                          style={{
                            flexGrow: slides ? 1 : 0,
                            flexShrink: slides ? 0 : 1,
                            overflow: "clip",
                          }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 4,
                                alignItems: "center",
                                overflow: "clip",
                              }}
                            >
                              {slides ? (
                                <BsFileEarmarkPdfFill
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              ) : (
                                <BsFileEarmarkPdf
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              )}
                              <p
                                style={{
                                  overflow: "clip",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Slides
                              </p>
                            </div>
                          }
                          isClickable={
                            !slides
                            // || [COMPLETED_OR_UPLOADING_AUDIO, CURRENT_LIVE_TRANSCRIPT].includes(
                            //   state
                            // )
                          }
                          onClick={() => {
                            closeAllPanelsExcept(PanelType.Slides);
                          }}
                          buttonStyle={
                            darkMode
                              ? buttonStyles.panelTabsDarkMode
                              : buttonStyles.panelTabs
                          }
                        />
                      </TabUnderline>
                    </>
                  )}
                </div>
              </div>

              {/* Transcript Panel */}
              <TranscriptPanel
                started={started}
                setEditMode={setEditMode}
                editMode={editMode}
                GPT={GPT}
                transcript={transcript}
                chat={chat}
                trptRef={trptRef}
                transcriptAutoscroll={transcriptAutoscroll.current}
                setTranscriptAutoscroll={(newValue) =>
                  (transcriptAutoscroll.current = newValue)
                }
                supabase={supabase}
                ownerOfTranscript={ownerOfTranscript}
                highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                setHighlightedSummaryBlurbIndex={
                  setHighlightedSummaryBlurbIndex
                }
                highlightedTranscriptBlurbIndex={
                  highlightedTranscriptBlurbIndex
                }
                setHighlightedTranscriptBlurbIndex={
                  setHighlightedTranscriptBlurbIndex
                }
                smallWindow={smallWindow}
                highlightTranscriptBlurbTimeout={
                  highlightTranscriptBlurbTimeout
                }
                highlightSummaryBlurbTimeout={highlightSummaryBlurbTimeout}
                setShowCheckout={setShowCheckout}
                setShowTranscriptUpgradeButton={setShowTranscriptUpgradeButton}
                showTranscriptUpgradeButton={showTranscriptUpgradeButton}
                selectedTranscriptBlurbIndices={selectedTranscriptBlurbIndices}
                setSelectedTranscriptBlurbIndices={
                  setSelectedTranscriptBlurbIndices
                }
                setNotOwnerPoints={setNotOwnerPoints}
                setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
                setNotOwnerAction={setNotOwnerAction}
                setShowInviteFriendsPanel={setShowInviteFriendsPanel}
              />
              <VideoPanel
                supabase={supabase}
                displayVideo={displayVideo}
                setDisplayVideo={setDisplayVideo}
              />

              {panelCutoff && (
                <>
                  <GPTPanel
                    GPT={GPT}
                    supabase={supabase}
                    started={started}
                    setChat={setChat}
                    chat={chat}
                    setGPT={setGPT}
                    highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                    setHighlightedSummaryBlurbIndex={
                      setHighlightedSummaryBlurbIndex
                    }
                    setHighlightedTranscriptBlurbIndex={
                      setHighlightedTranscriptBlurbIndex
                    }
                    highlightTranscriptBlurbTimeout={
                      highlightTranscriptBlurbTimeout
                    }
                    ownerOfTranscript={ownerOfTranscript}
                    paused={paused}
                  />
                  <ProfessorOssyPanel
                    chat={chat}
                    setChat={setChat}
                    supabase={supabase}
                    paused={paused}
                    started={started}
                    panelCutoff={panelCutoff}
                    highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                    setHighlightedSummaryBlurbIndex={
                      setHighlightedSummaryBlurbIndex
                    }
                    setHighlightedTranscriptBlurbIndex={
                      setHighlightedTranscriptBlurbIndex
                    }
                    highlightTranscriptBlurbTimeout={
                      highlightTranscriptBlurbTimeout
                    }
                    selectedTranscriptBlurbIndices={
                      selectedTranscriptBlurbIndices
                    }
                    setSelectedTranscriptBlurbIndices={
                      setSelectedTranscriptBlurbIndices
                    }
                    contentType={contentType}
                    ownerOfTranscript={ownerOfTranscript}
                  />
                  <SlidesPanel
                    supabase={supabase}
                    slides={slides}
                    setSlides={setSlides}
                    setGuestUserAction={setGuestUserAction}
                    setLogInPanel={setLogInPanel}
                    setFunctionAfterLogIn={setFunctionAfterLogIn}
                    courseMaterialType={contentTypes.TRANSCRIPT}
                    ownerOfTranscript={ownerOfTranscript}
                    setNotOwnerPoints={setNotOwnerPoints}
                    setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
                    setNotOwnerAction={setNotOwnerAction}
                  />
                </>
              )}
            </div>

            {!panelCutoff && (
              <div
                style={{
                  flex: 1,
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 16,
                  maxWidth: "min(1500px, 90%)",

                  // this line is very necessary. keeps the panel from overflowing when text is really long
                  overflow: "clip",
                  width: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    marginBottom: 8,
                    borderBottomColor: darkMode ? colors.gray1 : colors.gray,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                  }}
                >
                  <TabUnderline active={GPT}>
                    <Button
                      style={{
                        overflow: "clip",
                        flexGrow: GPT ? 1 : 0,
                        flexShrink: GPT ? 0 : 1,
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                            overflow: "clip",
                          }}
                        >
                          {GPT ? (
                            <BsFileTextFill
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          ) : (
                            <BsFileText
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            AI Summaries
                          </p>
                        </div>
                      }
                      onClick={() => {
                        closeAllPanelsExcept(PanelType.GPT);
                      }}
                      isClickable={
                        //   [
                        //   CURRENT_LIVE_TRANSCRIPT,
                        //   COMPLETED_OR_UPLOADING_AUDIO,
                        // ].includes(state)
                        !GPT
                      }
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                    />
                  </TabUnderline>

                  <TabUnderline active={chat}>
                    <Button
                      style={{
                        overflow: "clip",
                        flexGrow: chat ? 1 : 0,
                        flexShrink: chat ? 0 : 1,
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            overflow: "clip",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                          }}
                        >
                          {chat ? (
                            <BsChatFill
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          ) : (
                            <BsChat style={{ minWidth: 14, maxWidth: 14 }} />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Chatbot (Professor Ossy)
                          </p>
                        </div>
                      }
                      isClickable={
                        !chat
                        // || [COMPLETED_OR_UPLOADING_AUDIO, CURRENT_LIVE_TRANSCRIPT].includes(
                        //   state
                        // )
                      }
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                      onClick={() => {
                        closeAllPanelsExcept(PanelType.Chatbot);
                      }}
                    />
                  </TabUnderline>

                  <TabUnderline active={slides}>
                    <Button
                      style={{
                        overflow: "clip",
                        flexGrow: slides ? 1 : 0,
                        flexShrink: slides ? 0 : 1,
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            overflow: "clip",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                          }}
                        >
                          {slides ? (
                            <BsFileEarmarkPdfFill
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          ) : (
                            <BsFileEarmarkPdf
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Slides
                          </p>
                        </div>
                      }
                      isClickable={!slides}
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                      onClick={() => {
                        closeAllPanelsExcept(PanelType.Slides);
                      }}
                    />
                  </TabUnderline>
                </div>

                <GPTPanel
                  GPT={GPT}
                  supabase={supabase}
                  started={started}
                  setChat={setChat}
                  chat={chat}
                  setGPT={setGPT}
                  highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                  setHighlightedSummaryBlurbIndex={
                    setHighlightedSummaryBlurbIndex
                  }
                  setHighlightedTranscriptBlurbIndex={
                    setHighlightedTranscriptBlurbIndex
                  }
                  highlightTranscriptBlurbTimeout={
                    highlightTranscriptBlurbTimeout
                  }
                  ownerOfTranscript={ownerOfTranscript}
                />
                <ProfessorOssyPanel
                  chat={chat}
                  setChat={setChat}
                  supabase={supabase}
                  paused={paused}
                  started={started}
                  panelCutoff={panelCutoff}
                  highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                  setHighlightedSummaryBlurbIndex={
                    setHighlightedSummaryBlurbIndex
                  }
                  setHighlightedTranscriptBlurbIndex={
                    setHighlightedTranscriptBlurbIndex
                  }
                  highlightTranscriptBlurbTimeout={
                    highlightTranscriptBlurbTimeout
                  }
                  selectedTranscriptBlurbIndices={
                    selectedTranscriptBlurbIndices
                  }
                  setSelectedTranscriptBlurbIndices={
                    setSelectedTranscriptBlurbIndices
                  }
                  contentType={contentType}
                  ownerOfTranscript={ownerOfTranscript}
                />
                <SlidesPanel
                  supabase={supabase}
                  slides={slides}
                  setSlides={setSlides}
                  setGuestUserAction={setGuestUserAction}
                  setLogInPanel={setLogInPanel}
                  setFunctionAfterLogIn={setFunctionAfterLogIn}
                  courseMaterialType={contentTypes.TRANSCRIPT}
                  ownerOfTranscript={ownerOfTranscript}
                  setNotOwnerPoints={setNotOwnerPoints}
                  setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
                  setNotOwnerAction={setNotOwnerAction}
                />
              </div>
            )}
          </div>

          {/* control panel */}
          <div
            style={{
              // minHeight: bottomHeight,
              display: "flex",
              justifyContent: "center",
              marginBottom: panelCutoff ? "16px" : "40px",
            }}
          >
            <ControlPanel
              started={started}
              setTranscriptionSettings={setTranscriptionSettings}
              loading={loading}
              resumeTranscribing={resumeTranscribing}
              paused={paused}
              pauseTranscribing={pauseTranscribing}
              GPT={GPT}
              setGPT={setGPT}
              editMode={editMode}
              setEditMode={setEditMode}
              stopTranscribing={stopTranscribing}
              setShowSummaryPanel={setShowSummaryPanel}
              downloadOptionsVisible={downloadOptionsVisible}
              setDownloadOptionsVisible={setDownloadOptionsVisible}
              ownerOfTranscript={ownerOfTranscript}
              supabase={supabase}
              chat={chat}
              setChat={setChat}
              transcriptMode={transcriptMode}
              setNotOwnerPoints={setNotOwnerPoints}
              setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
              setNotOwnerAction={setNotOwnerAction}
            />
          </div>

          {/* summary panel for lectures */}
          {showSummaryPanel && (
            <SummaryPanel
              setShowSummaryPanel={setShowSummaryPanel}
              supabase={supabase}
              ownerOfContent={ownerOfTranscript}
              content={{
                summaryBulletPoints,
                summarySentences,
                contentArray,
              }}
              contentType={contentType}
            />
          )}

          {showCheckout && (
            <CheckoutPanel
              setShowCheckout={setShowCheckout}
              showCheckout={showCheckout}
              supabase={supabase}
              setShowTranscriptUpgradeButton={setShowTranscriptUpgradeButton}
              showTranscriptUpgradeButton={showTranscriptUpgradeButton}
              ownerOfTranscript={ownerOfTranscript}
            />
          )}

          {/* feedback button */}
          {/* {!smallWindow && <FeedbackButton setFeedbackForm={setFeedbackForm} />} */}

          {/* feedback form */}
          {feedbackForm && (
            <FeedbackForm
              setFeedbackForm={setFeedbackForm}
              email={email}
              feedback={feedback}
              setEmail={setEmail}
              setFeedback={setFeedback}
              supabase={supabase}
              setSubmitFeedbackLoading={setSubmitFeedbackLoading}
              submitFeedbackLoading={submitFeedbackLoading}
            />
          )}

          {/* prompt user to pay us money */}
          {showUpgradePanel && (
            <OssyPlusPaywall
              supabase={supabase}
              setShowUpgradePanel={setShowUpgradePanel}
              setLoading={setLoading}
              showUpgradePanel={showUpgradePanel}
              usageDuration={weekAudioDuration}
              setShowInviteFriendsPanel={setShowInviteFriendsPanel}
            />
          )}

          {showInviteFriendsPanel && (
            <InviteThreeFriendsPanel
              setShowInviteFriendsPanel={setShowInviteFriendsPanel}
              showInviteFriendsPanel={showInviteFriendsPanel}
              supabase={supabase}
              midTranscript={started}
              setShowCheckout={setShowCheckout}
              setShowTranscriptUpgradeButton={setShowTranscriptUpgradeButton}
            />
          )}

          {/* settings panel */}
          {transcriptionSettings && (
            <TranscriptSettingsPanel
              setSaveAudio={setSaveAudio}
              saveAudio={saveAudio}
              customVocab={customVocab}
              filterProfanity={filterProfanity}
              filterDisfluencies={filterDisfluencies}
              startTranscribing={startTranscribing}
              setCustomVocab={setCustomVocab}
              setLanguage={setLanguage}
              setFilterProfanity={setFilterProfanity}
              setFilterDisfluencies={setFilterDisfluencies}
              setTranscriptionSettings={setTranscriptionSettings}
              setLogInPanel={setLogInPanel}
              setGuestUserAction={setGuestUserAction}
              setLoading={setLoading}
              supabase={supabase}
              setShowUpgradePanel={setShowUpgradePanel}
              setFunctionAfterLogIn={setFunctionAfterLogIn}
              transcriptMode={transcriptMode}
            />
          )}
          {autoPausePanel && (
            <AutoPausePanel setAutoPausePanel={setAutoPausePanel} />
          )}
          {logInPanel && (
            <LogInPanel
              setLogInPanel={setLogInPanel}
              setLoading={setLoading}
              supabase={supabase}
              userAction={guestUserAction}
              functionAfterLogin={functionAfterLogin}
            />
          )}
          {displayPostTranscriptFeedbackPanel && (
            <PostTranscriptFeedbackPanel
              supabase={supabase}
            ></PostTranscriptFeedbackPanel>
          )}
          {audioUploading && (
            <>
              {/* dark overlay */}
              <div
                style={{
                  flex: 1,
                  position: "fixed",
                  backgroundColor: "black",
                  opacity: 0.5,
                  width: "100%",
                  height: "100%",
                }}
              ></div>

              <div
                style={{
                  color: "black",
                  borderRadius: 6,
                  width: 350,
                  maxWidth: "75dvw",
                  marginBottom: 6,
                  display: "flex",
                  alignItems: "center",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  boxShadow: boxShadow,
                  padding: 20,
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ textAlign: "left", fontSize: 12, marginBottom: 8 }}
                >
                  Loading your audio... (Don't close this tab)
                </div>
                <div
                  style={{
                    height: 20,
                    marginLeft: 10,
                    width: "100%",
                    backgroundColor: colors.gray,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: colors.green,
                      height: 20,
                      width: `${audioUploadProgress}%`,
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {displayNotOwnerTooltip && (
            <NotOwnerTooltip
              notOwnerPoints={notOwnerPoints}
              contentType={contentType}
              action={notOwnerAction}
              setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TranscriptPage;
