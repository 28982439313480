import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import loadingSymbol from "../assets/loading.gif";
import { GoGear } from "react-icons/go";
import {
  fetchFolders,
  fetchFolder,
  fetchCourseContent,
  fetchUserData,
  uploadPdfDocument,
  fetchContentsArray,
  saveContent,
  fetchWeekTranscriptionLimit,
} from "../transcriptSlice";
import {
  colors,
  logger,
  LogCategory,
  getIsMac,
  addScrollBarUI,
  setScrollbarLightOrDarkMode,
  transcriptsType,
  contentTypes,
} from "../utility/utility.mjs";
import {
  SummaryPanel,
  Folder,
  Transcript,
  DeleteFolderWarningPanel,
  SelectFolderPanel,
  RightClickFolderDropDown,
  RightClickTranscriptDropDown,
  Button,
  ToolTip,
  HomeLeftMenu,
  LogInPanel,
} from "../components";
import { useLoaderData } from "react-router-dom";
import HomeHeader2 from "../components/HomeHeader2";
import { BsCheck, BsFileEarmarkPdf } from "react-icons/bs";
import { PiCaretDownLight, PiCaretUpLight } from "react-icons/pi";
import { FiXCircle } from "react-icons/fi";
import RightClickDocumentDropdown from "../components/RightClickDocumentDropdown";
import Document from "../components/Document";
import { initialState } from "../data/initialState";
import SharePanel from "../components/SharePanel";
import actions from "../features/login/components/actions.json";
import NotOwnerTooltip from "../components/NotOwnerTooltip";
import CreateInSharedFolderPanel from "../components/CreateInSharedFolderPanel";
import { buttonStyles } from "../components/Button";
import AddToSharedFolderPanel from "../components/AddToSharedFolderPanel";

const HomePage = ({ supabase, shared, isOwnerOfContent }) => {
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const justLoggedOut = useSelector((state) => state.routes.justLoggedOut);
  const folders = useSelector((state) => state.routes.folders);
  const userId = useSelector((state) => state.routes.userId);
  const contentsArray = useSelector((state) => state.routes.contentsArray);
  const [showSummaryPanel, setShowSummaryPanel] = useState(false);
  const [deleteFolderWarningPanel, setDeleteFolderWarningPanel] =
    useState(false);

  const [rightClickObject, setRightClickObject] = useState({
    type: contentTypes.NONE,
    index: -1,
    thing: undefined,
    active: false,
  });

  const folderUrl = useLoaderData();
  const isFolderInUrl = typeof folderUrl === "string";

  const fileInputRef = useRef();
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [displayedCreateInSharedFolderPanel, setDisplayInSharedFolderPanel] =
    useState(false);
  const [sharedContentMode, setSharedContentMode] = useState();

  const [displayAddToSharedFolder, setDisplayAddToSharedFolder] =
    useState(false);
  const [addToSharedFolderIndex, setAddToSharedFolderIndex] = useState(-1);
  const [addToSharedFolderContentIndex, setAddToSharedContentIndex] =
    useState(-1);

  const {
    name: fileName,
    size: fileSize,
    type: fileType,
  } = fileToUpload ?? { fileName: "asdf" };

  let binType = transcriptsType.HOME;
  if (shared) {
    binType = transcriptsType.SHARED_TRANSCRIPTS;
  } else if (isFolderInUrl) {
    binType = transcriptsType.FOLDER_TRANSCRIPTS;
  }

  const [selected, setSelected] = useState(binType);
  const [selectedTranscriptsType, setSelectedTranscriptsType] =
    useState(binType);

  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [transcriptTitleEditIndex, setTranscriptTitleEditIndex] = useState(-1);
  const [folderTitleEditIndex, setFolderTitleEditIndex] = useState(-1);
  const [editingTranscriptTitle, setEditingTranscriptTitle] = useState(false);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [isFolderSelected, setIsFolderSelected] = useState(isFolderInUrl);
  const dispatch = useDispatch();
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 575px)").matches
  );
  const [selectFolderPanel, setSelectFolderPanel] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const startFetchTranscriptIndex = useRef(0);
  const endOfHomePage = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const observerRef = useRef(null);

  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("");
  const [showFileUploadPanel, setShowFileUploadPanel] = useState(false);
  const [condenseFileUploadPanel, setCondenseFileUploadPanel] = useState(false);
  const [displaySharePanel, setDisplaySharePanel] = useState(false);
  const [guestUserAction, setGuestUserAction] = useState(
    actions.VIEW_FOLDER_ATTEMPT
  );
  const [logInPanel, setLogInPanel] = useState(false);
  const folder = useSelector((state) => state.routes.folder);
  const isWithinFolder = folder.id !== initialState.folder.id;

  const [displayNotOwnerTooltip, setDisplayNotOwnerTooltip] = useState(false);
  const [notOwnerAction, setNotOwnerAction] = useState();
  const [notOwnerPoints, setNotOwnerPoints] = useState({ x: 0, y: 0 });

  const isMac = getIsMac();

  // update resize
  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 575px)").matches;
      setSmallWindow(newSmallWindow);

      // if the screen expands, reset small menu to disappear
      if (!newSmallWindow && menuOpen) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, [menuOpen]);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  // we have to change the color of the latex to white to see it in dark mode
  useEffect(() => {
    let styleElement = document.createElement("style");
    styleElement.innerHTML = `mjx-container {color: ${
      darkMode ? "white" : "gray"
    };}`;
    document.head.appendChild(styleElement);
  }, [darkMode]);

  // fetch user data and folders
  useEffect(() => {
    if (!isLoggedIn) {
      setLogInPanel(true);
    }
    const fetchAllData = async () => {
      if (isLoggedIn) {
        // finish awaiting user data before fetching anything else
        await dispatch(fetchUserData({ supabase })).unwrap();
        logger("fetching all user data");

        // fetch all the folders for the folder panel if not within a folder
        // if (!(folder.id !== initialState.folder.id))
        dispatch(
          fetchFolders({
            supabase,
            selectedTranscriptsType: selectedTranscriptsType,
          })
        );
      }

      // if we have a folder, display it
      if (selectedTranscriptsType === transcriptsType.FOLDER_TRANSCRIPTS) {
        const folderData = await dispatch(
          fetchFolder({ supabase, folderUrl })
        ).unwrap();

        const {
          folder: { folderName: _folderName, id },
          ownerOfFolder,
        } = folderData;
        setSelected(_folderName);

        // immediately save the folder if you are not the owner of the folder
        if (!isOwnerOfContent) {
          dispatch(
            saveContent({
              supabase,
              contentId: id,
              contentType: contentTypes.FOLDER,
            })
          );
        }
      }
    };
    fetchAllData();
  }, [isLoggedIn]);

  // iniitally fetch 20 transcripts and
  // more transcripts are you scroll down for infinite scroll
  useEffect(() => {
    const fetchInitialTranscripts = async () => {
      logger("fetching course content...");
      logger(isFolderInUrl ? folderUrl : "");
      startFetchTranscriptIndex.current = 0;
      await dispatch(
        fetchContentsArray({
          supabase,
          startFetchTranscriptIndex,
          selectedTranscriptsType: selectedTranscriptsType,
          folderUrl: isFolderInUrl ? folderUrl : "",
          isInitialFetch: true,
        })
      ).unwrap();
      createObserver();
    };

    // observer for inifinte scroll, detects when you reach the bottom of the page
    const createObserver = () => {
      observerRef.current = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && isLoading === false) {
            logger([LogCategory.DEBUG], "Fetching Transcripts");
            setIsLoading(true);
            await dispatch(
              fetchContentsArray({
                supabase,
                startFetchTranscriptIndex,
                selectedTranscriptsType: selectedTranscriptsType,
                folderUrl: isFolderInUrl ? folderUrl : "",
                isInitialFetch: false,
              })
            ).unwrap();
            setIsLoading(false);
          }
        },
        { threshold: 1 }
      );
      if (!endOfHomePage.current) {
        return;
      }
      observerRef.current.observe(endOfHomePage.current);
    };

    fetchInitialTranscripts();
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [selectedTranscriptsType]);

  useEffect(() => {
    // if the user isn't logged in,
    if (justLoggedOut) {
      logger([LogCategory.INFO], "NO LONGER LOGGED IN");
      const protocol = window.location.protocol
        ? window.location.protocol + "//"
        : "";
      const host = window.location.host;

      // if they're just trying to go to www.ossy.ai, take them to the new transcript screen
      window.location.replace(`${protocol}${host}/login`);
    }
  }, [isLoggedIn, dispatch, supabase, justLoggedOut]);

  useEffect(() => {
    // convert all the latex to actual math when page first loads and when another folder is selected
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }

    return () => window.MathJax.typesetClear();
  }, [contentsArray]);

  // save the pdf file to supabase and then displays it
  const saveFile = (file) => {
    // read the files content
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (e) => {
      const slidesArrayBuffer = e.target.result;
      //  save the pdf file to supabase
      dispatch(
        uploadPdfDocument({
          supabase,
          slidesArrayBuffer,
          fileUploadErrorMsg,
          fileName: file.name,
          folderUrl: isFolderInUrl ? folderUrl : "",
          setUploadingFile,
        })
      );
    };
  };

  // triggered after drag and drop or click
  const handleSubmit = (file) => {
    logger([LogCategory.DEBUG], "file: ", file);

    const isFileValid = getIsFileValid(file);
    if (isFileValid) {
      setShowFileUploadPanel(true);
      setCondenseFileUploadPanel(false);
      setUploadingFile(true);
      saveFile(file);
    } else {
      setUploadingFile(false);
    }
  };

  // determine if the input file is valid
  const getIsFileValid = (file) => {
    const MAX_FILE_SIZE_IN_MB = 15;

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / 1024 ** 2;
    // if file type is not PDF
    if (file.type !== "application/pdf") {
      alert("Incorrect file type. File must be a PDF.");
      return false;
    }
    // if file size exceeds the limit
    if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
      alert(
        `File size is ${fileSizeInMB.toFixed(
          2
        )} mb. Max file size allowed is ${MAX_FILE_SIZE_IN_MB} mb.`
      );
      setFileUploadErrorMsg(
        `File Size: ${fileSizeInMB.toFixed(
          2
        )} MB. Max file size allowed is ${MAX_FILE_SIZE_IN_MB} MB.`
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(fetchWeekTranscriptionLimit({ supabase }));
  }, []);

  return (
    // full screen
    <>
      {/* the actual home page */}
      <div
        style={{
          flex: 1,
          // backgroundColor: darkMode ? "" : "white",
          backgroundColor: darkMode ? colors.black : "white",
          color: darkMode ? "white" : "black",
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          overflow: "hidden",
        }}
      >
        {/* everything below the header */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            overflowX: "clip",
            overflowY: "hidden",
          }}
        >
          {/* left menu */}
          <HomeLeftMenu
            smallWindow={smallWindow}
            menuOpen={menuOpen}
            supabase={supabase}
            setMenuOpen={setMenuOpen}
            selected={selected}
            isFolderSelected={isFolderSelected}
            selectedTranscriptsType={selectedTranscriptsType}
            setFileToUpload={setFileToUpload}
            fileInputRef={fileInputRef}
            isOwnerOfContent={isOwnerOfContent}
            setNotOwnerAction={setNotOwnerAction}
            setNotOwnerPoints={setNotOwnerPoints}
            setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
            setDisplayInSharedFolderPanel={setDisplayInSharedFolderPanel}
            setSharedContentMode={setSharedContentMode}
          />

          {/* main page */}
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              fontSize: 24,
              // display: "flex",
              flexDirection: "column",
              overflow: "clip",
              overflowY: "clip",
              minHeight: "max-content",
            }}
          >
            <HomeHeader2
              smallWindow={smallWindow}
              setMenuOpen={setMenuOpen}
              supabase={supabase}
              showUpgrade={!isPaidUser}
            />

            <div
              style={{
                paddingTop: 40,
              }}
            />
            {/* title of what is being viewed ("All Transcripts" or folder name) */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: smallWindow ? 30 : 40,
                marginRight: smallWindow ? (isMac ? 30 : 42) : isMac ? 40 : 52,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                }}
              >
                <p
                  style={{
                    fontWeight: 500,
                    overflow: "hidden",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",

                    // setting width to 100% takes away the ellipsis from long text
                    alignItems: "center",
                  }}
                >
                  {selected}
                </p>
                {folder.id !== initialState.folder.id && (
                  <GoGear
                    style={{
                      marginLeft: 8,
                      minWidth: 16,
                      maxWidth: 16,
                      minHeight: 16,
                      maxHeight: 16,
                      padding: 8,
                      cursor: "pointer",
                      color: darkMode ? colors.lightGray : "gray",
                      borderRadius: 1000000,
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray1
                        : colors.lightGray;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                    onClick={(event) => {
                      event.preventDefault();

                      // leave a little clearance vertically
                      setPoints({ x: event.pageX, y: event.pageY + 18 });
                      setRightClickObject({
                        type: contentTypes.FOLDER,
                        index: -1,
                        thing: folder,
                        active: true,
                      });
                    }}
                  />
                )}
              </div>
              <Button
                content={
                  <p style={{ wordBreak: "keep-all" }}>Chat with folder</p>
                }
                isClickable={isFolderSelected}
                style={{
                  // fontSize: 12,
                  fontWeight: 500,
                }}
                // padding={6}
                onClick={() => {
                  window.location.pathname = `/f/${folderUrl}/chat`;
                }}
                buttonStyle={buttonStyles.outline}
                toolTip={
                  !isFolderSelected && (
                    <ToolTip
                      text={"Create a folder to chat"}
                      top={true}
                      removeOnClick={false}
                    />
                  )
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                // overflowY: "clip",
              }}
              data-testid="scrolling-container"
              onScroll={(event) => {
                if (!isMac) {
                  addScrollBarUI(event);
                }
              }}
            >
              {folders.length > 0 &&
                (selected === transcriptsType.HOME ||
                  selected === transcriptsType.SHARED_TRANSCRIPTS) && (
                  <>
                    <p
                      style={{
                        color: darkMode ? "white" : "gray",
                        fontSize: 16,
                        paddingLeft: smallWindow ? 30 : 40,
                        fontWeight: 500,
                      }}
                    >
                      {"Folders"}
                    </p>

                    <div
                      style={{
                        display: "grid",
                        // flexWrap: "wrap"
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(200px, 1fr))",
                        // justifyContent: smallWindow ? "center" : "flex-start",
                        width: `100%`,
                        boxSizing: "border-box",
                        // width: "100%",
                        // alignItems: "flex-start",
                        gap: 22,
                        paddingTop: folders.length > 0 ? 16 : 0,
                        paddingBottom: 32,
                        paddingLeft: smallWindow ? 30 : 40,
                        paddingRight: smallWindow ? 30 : 40,
                        overflowX: "clip",
                      }}
                    >
                      {folders.map((folder, index) => {
                        return (
                          <Folder
                            selected={selected}
                            smallWindow={smallWindow}
                            setMenuOpen={setMenuOpen}
                            setSelected={setSelected}
                            setSelectedTranscriptsType={
                              setSelectedTranscriptsType
                            }
                            setIsFolderSelected={setIsFolderSelected}
                            isFolderSelected={isFolderSelected}
                            folderTitleEditIndex={folderTitleEditIndex}
                            setPoints={setPoints}
                            setRightClickObject={setRightClickObject}
                            setFolderTitleEditIndex={setFolderTitleEditIndex}
                            userId={userId}
                            folder={folder}
                            supabase={supabase}
                            index={index}
                            key={index}
                            isOwner={isOwnerOfContent}
                            setNotOwnerAction={setNotOwnerAction}
                            setDisplayNotOwnerTooltip={
                              setDisplayNotOwnerTooltip
                            }
                            setNotOwnerPoints={setNotOwnerPoints}
                            setDisplayAddToSharedFolder={
                              setDisplayAddToSharedFolder
                            }
                            setAddToSharedFolderIndex={
                              setAddToSharedFolderIndex
                            }
                            setAddToSharedContentIndex={
                              setAddToSharedContentIndex
                            }
                          />
                        );
                      })}
                    </div>
                  </>
                )}

              {contentsArray.length === 0 && (
                <p
                  style={{
                    color: darkMode ? "white" : "gray",
                    fontSize: 16,
                    paddingLeft: smallWindow ? 30 : 40,
                  }}
                >
                  {(() => {
                    switch (true) {
                      case isOwnerOfContent:
                        return "Your Transcripts & Documents will appear here.";
                      case !isOwnerOfContent && isWithinFolder:
                        return "This Folder is Empty.";
                      case !isOwnerOfContent &&
                        selected === transcriptsType.SHARED_TRANSCRIPTS:
                        return "Your shared Transcripts & Documents will appear here.";
                      default:
                        return "Your Transcripts & Documents will appear here.";
                    }
                  })()}
                </p>
              )}
              {contentsArray.length > 0 && (
                <>
                  <p
                    style={{
                      color: darkMode ? "white" : "gray",
                      display: isFolderSelected ? "none" : "inline",
                      fontSize: 16,
                      fontWeight: 500,
                      paddingLeft: smallWindow ? 30 : 40,
                    }}
                  >
                    {"Transcripts & Documents"}
                  </p>
                  <div
                    style={{
                      justifyContent: smallWindow ? "center" : "flex-start",
                      gap: 22,
                      paddingTop: contentsArray.length > 0 ? 16 : 0,
                      paddingBottom: contentsArray.length > 0 ? 16 : 0,
                      paddingLeft: smallWindow ? 30 : 40,
                      paddingRight: smallWindow ? 30 : 40,
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(200px, 1fr))",
                      // justifyContent: smallWindow ? "center" : "flex-start",
                      width: `100%`,
                      boxSizing: "border-box",
                    }}
                    data-testid="transcripts-container"
                  >
                    {contentsArray.map((content, index) => {
                      const ContentType =
                        content.contentType === contentTypes.TRANSCRIPT
                          ? Transcript
                          : Document;
                      return (
                        <ContentType
                          editingTranscriptTitle={editingTranscriptTitle}
                          isFolderSelected={isFolderSelected}
                          key={index}
                          index={index}
                          setPoints={setPoints}
                          rightClickObject={rightClickObject}
                          setRightClickObject={setRightClickObject}
                          smallWindow={smallWindow}
                          setEditingTranscriptTitle={setEditingTranscriptTitle}
                          transcriptTitleEditIndex={transcriptTitleEditIndex}
                          setTranscriptTitleEditIndex={
                            setTranscriptTitleEditIndex
                          }
                          content={content}
                          userId={userId}
                          supabase={supabase}
                        />
                      );
                    })}
                    {/* Bar to detect when you reach of the grid and should load more transcripts to the page */}

                    <div
                      ref={endOfHomePage}
                      style={{ width: "100%" }}
                      data-testid="home-bottom-scroll"
                    />
                  </div>
                </>
              )}

              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    paddingBottom: 20,
                  }}
                >
                  <img
                    src={loadingSymbol}
                    style={{ width: 16, height: 16, margin: "8px 0px" }}
                    alt="Loading... "
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf"
        onChange={(event) => {
          event.preventDefault();
          setFileToUpload(event.target.files[0]);
          logger(event.target.files[0]);
          handleSubmit(event.target.files[0]);
        }}
        style={{ display: "none" }}
      />

      <div
        style={{
          position: "fixed",
          right: 24,
          width: 280,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          boxShadow: "0.5px 0.5px 5px rgba(0, 0, 0, 0.15)",
          backgroundColor: "white",
          bottom: condenseFileUploadPanel ? -40 : 0,
          display: showFileUploadPanel ? "inline" : "none",
        }}
      >
        <div
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: colors.lightGray,
            padding: 8,
            fontSize: 14,
            fontWeight: 500,
            height: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>{uploadingFile ? "Uploading a file..." : "Upload complete"}</p>
          <div style={{ display: "flex", gap: 4 }}>
            <div
              style={{
                padding: 4,
                borderRadius: 100,
                display: "flex",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = colors.gray;
              }}
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
              onClick={(e) => {
                setCondenseFileUploadPanel(!condenseFileUploadPanel);
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              {condenseFileUploadPanel ? (
                <PiCaretDownLight />
              ) : (
                <PiCaretUpLight />
              )}
            </div>
            <div
              style={{
                padding: 4,
                borderRadius: 100,
                display: "flex",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = colors.gray;
              }}
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
              onClick={(e) => {
                setShowFileUploadPanel(false);
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <FiXCircle />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 8,
            flexDirection: "row",
            height: 24,
            justifyContent: "space-between",
            gap: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 12,
              overflow: "clip",
              alignItems: "center",
            }}
          >
            <BsFileEarmarkPdf
              color="red"
              opacity={uploadingFile ? 0.5 : 1}
              size={16}
            />
            <p
              style={{
                color: "gray",
                whiteSpace: "nowrap",
                overflow: "clip",
                textOverflow: "ellipsis",
                fontSize: 13,
              }}
            >
              {fileName}
            </p>
          </div>
          {uploadingFile ? (
            <img src={loadingSymbol} alt="loading" style={{ height: 16 }} />
          ) : (
            <div
              style={{
                backgroundColor: "mediumseagreen",
                display: "flex",
                borderRadius: 1000,
                padding: 1,
              }}
            >
              <BsCheck color={"white"} />
            </div>
          )}
        </div>
      </div>

      {deleteFolderWarningPanel && (
        <DeleteFolderWarningPanel
          setDeleteFolderWarningPanel={setDeleteFolderWarningPanel}
          selected={selected}
          setSelected={setSelected}
          setSelectedTranscriptsType={setSelectedTranscriptsType}
          setIsFolderSelected={setIsFolderSelected}
          supabase={supabase}
          rightClickObject={rightClickObject}
        />
      )}

      {selectFolderPanel && (
        <SelectFolderPanel
          supabase={supabase}
          setSelectFolderPanel={setSelectFolderPanel}
          setSelected={setSelected}
          setSelectedTranscriptsType={setSelectedTranscriptsType}
          setIsFolderSelected={setIsFolderSelected}
          rightClickObject={rightClickObject}
          setDisplayAddToSharedFolder={setDisplayAddToSharedFolder}
          setAddToSharedFolderIndex={setAddToSharedFolderIndex}
          setAddToSharedContentIndex={setAddToSharedContentIndex}
        />
      )}

      {/* right clicked a folder folders */}
      {rightClickObject.type === contentTypes.FOLDER &&
        rightClickObject.active && (
          <RightClickFolderDropDown
            supabase={supabase}
            points={points}
            setFolderTitleEditIndex={setFolderTitleEditIndex}
            setDeleteFolderWarningPanel={setDeleteFolderWarningPanel}
            selected={selected}
            setSelected={setSelected}
            setSelectedTranscriptsType={setSelectedTranscriptsType}
            isFolderSelected={isFolderSelected}
            setIsFolderSelected={setIsFolderSelected}
            setRightClickObject={setRightClickObject}
            rightClickObject={rightClickObject}
            setDisplaySharePanel={setDisplaySharePanel}
            isOwner={isOwnerOfContent}
            isWithinFolder={isWithinFolder}
          />
        )}

      {/* right click a transcript */}
      {rightClickObject.type === contentTypes.TRANSCRIPT &&
        rightClickObject.active && (
          <RightClickTranscriptDropDown
            points={points}
            setTranscriptTitleEditIndex={setTranscriptTitleEditIndex}
            setEditingTranscriptTitle={setEditingTranscriptTitle}
            setShowSummaryPanel={setShowSummaryPanel}
            setSelectFolderPanel={setSelectFolderPanel}
            isOwner={isOwnerOfContent}
            supabase={supabase}
            setRightClickObject={setRightClickObject}
            rightClickObject={rightClickObject}
            isFolderSelected={isFolderSelected}
            selected={selected}
            isWithinFolder={isWithinFolder}
            setDisplaySharePanel={setDisplaySharePanel}
          />
        )}

      {/* right click a document */}
      {rightClickObject.type === initialState.document.contentType &&
        rightClickObject.active && (
          <RightClickDocumentDropdown
            points={points}
            setTranscriptTitleEditIndex={setTranscriptTitleEditIndex}
            setEditingTranscriptTitle={setEditingTranscriptTitle}
            setShowSummaryPanel={setShowSummaryPanel}
            setSelectFolderPanel={setSelectFolderPanel}
            isOwner={isOwnerOfContent}
            supabase={supabase}
            setRightClickObject={setRightClickObject}
            rightClickObject={rightClickObject}
            isFolderSelected={isFolderSelected}
            selected={selected}
            isWithinFolder={isWithinFolder}
            setDisplaySharePanel={setDisplaySharePanel}
          />
        )}

      {/* summary panel for lectures */}
      {showSummaryPanel && (
        <SummaryPanel
          setShowSummaryPanel={setShowSummaryPanel}
          supabase={supabase}
          ownerOfContent={isOwnerOfContent}
          rightClickObject={rightClickObject}
          contentType={rightClickObject.type}
        />
      )}

      {logInPanel && (
        <LogInPanel
          setLogInPanel={setLogInPanel}
          supabase={supabase}
          userAction={guestUserAction}
          functionAfterLogin={() => () => {}}
        />
      )}

      {displaySharePanel && (
        <SharePanel
          setDisplaySharePanel={setDisplaySharePanel}
          supabase={supabase}
          isOwnerOfContent={isOwnerOfContent}
          rightClickObject={rightClickObject}
        />
      )}

      {displayNotOwnerTooltip && (
        <NotOwnerTooltip
          notOwnerPoints={notOwnerPoints}
          contentType={contentTypes.FOLDER}
          action={notOwnerAction}
          setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
        />
      )}

      {displayedCreateInSharedFolderPanel && (
        <CreateInSharedFolderPanel
          setDisplayInSharedFolderPanel={setDisplayInSharedFolderPanel}
          sharedContentMode={sharedContentMode}
          fileInputRef={fileInputRef}
        />
      )}

      {displayAddToSharedFolder && (
        <AddToSharedFolderPanel
          supabase={supabase}
          setDisplayAddToSharedFolder={setDisplayAddToSharedFolder}
          addToSharedFolderIndex={addToSharedFolderIndex}
          addToSharedFolderContentIndex={addToSharedFolderContentIndex}
        />
      )}
    </>
  );
};
export default HomePage;
