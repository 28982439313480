import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { colors, logger, LogCategory } from "../../../utility/utility.mjs";
import {
  updateTranscriptArray,
  saveTranscriptEdits,
} from "../../../transcriptSlice";

const TranscriptText = ({
  index,
  editMode,
  supabase,
  text,
  GPT,
  listeningForSpeechIndicator = false,
  started,
  setHighlightedSummaryBlurbIndex,
  highlightSummaryBlurbTimeout,
  setHighlightedTranscriptBlurbIndex,
  highlightBlurb,
  setShowPaywall,
  alreadyShowedPaywall,
  paidBlurb,
  setSelectedTranscriptBlurbIndices,
  selectedTranscriptBlurbIndices,
  chat,
}) => {
  const { bookmarks, timestamps, contentArray } = useSelector(
    (state) => state.routes.transcript
  );

  // every blurb at an index greater than or equal to paidBlurbIndex is paid
  const darkMode = useSelector((state) => state.routes.darkMode);
  const prevTranscriptType = useSelector(
    (state) => state.routes.prevTranscriptType
  );
  const PARTIAL = useSelector((state) => state.routes.PARTIAL);
  const unmergedIndex = useSelector((state) => state.routes.unmergedIndex);
  const unlatexedIndex = useSelector((state) => state.routes.unlatexedIndex);
  const [isEditable, setIsEditable] = useState(false);

  const processingBlurb =
    (index >= unmergedIndex && started) ||
    (index >= unlatexedIndex && started) ||
    (index === contentArray.length - 1 && prevTranscriptType === PARTIAL);

  const madeEditableRef = useRef(false);
  const dispatch = useDispatch();

  // make the box editable if it's editable
  const setIsEditableFunc = () => {
    // you can't edit a blurb you have to pay for
    if (editMode) {
      if (paidBlurb) {
        setIsEditable(false);
      }

      // last blurb is partial; user should not be able to edit it
      else if (processingBlurb) {
        setIsEditable(false);
      }

      // listening for speech indicator should not be editable
      else if (listeningForSpeechIndicator) {
        setIsEditable(false);
      }

      // ok now we can edit the blurb,
      else {
        setIsEditable(true);
        // can only set to be editable once
        madeEditableRef.current = true;
      }
    }
    // not in edit mode, can't edit the blurb
    else {
      setIsEditable(false);
    }
  };

  useEffect(() => {
    let blurb = document.getElementById(`transcriptBlurbText ${index}`);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          paidBlurb &&
          !alreadyShowedPaywall.current
        ) {
          setShowPaywall(true);
          logger(`already showed paywall: ${alreadyShowedPaywall.current}`);
          alreadyShowedPaywall.current = true;
        }

        if (entry.isIntersecting && editMode) {
          // text box must be visible and currently in edit mode
          // can only set to be editable once
          if (!madeEditableRef.current && !isEditable && !paidBlurb) {
            setIsEditableFunc();
            blurb.innerHTML = text;
          }
        }
      },
      { threshold: 0.0 } // Adjust threshold as needed
    );
    // checks to see if blob is in the viewport
    observer.observe(blurb);

    if (!editMode) {
      madeEditableRef.current = false;
      // observer.unobserve(blurb);
      setIsEditable(false);
    }

    return () => {
      observer.disconnect();
    };
  }, [editMode, prevTranscriptType]);

  return (
    <span
      contenteditable={`${isEditable}`}
      style={{
        hyphens: "auto",
        wordBreak: "break-word",
        borderRadius: 6,
        WebkitBoxDecorationBreak: "clone",
        boxDecorationBreak: "clone",
        resize: "none",
        opacity: editMode && processingBlurb ? 0.5 : 1,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        WebkitBorderBottomLeftRadius: 6,
        WebkitBorderBottomRightRadius: 6,
        color: darkMode ? "white" : "black",
        WebkitBorderTopLeftRadius: 6,
        WebkitBorderTopRightRadius: 6,
        textAlign: "left",
        padding: 7,
        lineHeight: 2.5,
        fontWeight: 400,
        margin: 0,
        fontFamily: "poppins",
        border: `1px solid ${isEditable ? "gray" : "transparent"}`,
        width: editMode && !paidBlurb ? "100%" : "fit-content",
        backgroundColor: darkMode
          ? colors.gray1Number(bookmarks[index] || highlightBlurb ? 0.8 : 1)
          : colors.grayNumber(bookmarks[index] || highlightBlurb ? 0.8 : 1),
        fontSize: 16,
        cursor: paidBlurb
          ? "auto"
          : editMode
          ? "text"
          : listeningForSpeechIndicator
          ? "normal"
          : "pointer",
        outline: "0px solid transparent",
        position: "relative",
      }}
      onMouseEnter={(event) => {
        // cannot click to jump explanations when in edit more or when listening for speech
        if (paidBlurb || editMode || listeningForSpeechIndicator) {
          return;
        }
        event.currentTarget.style.background = darkMode
          ? colors.gray15
          : colors.graySlightlyDarker;
      }}
      onMouseLeave={(event) => {
        // cannot click to jump explanations when in edit more or when listening for speech
        if (paidBlurb || editMode || listeningForSpeechIndicator) {
          return;
        }
        event.currentTarget.style.backgroundColor = darkMode
          ? colors.gray1Number(bookmarks[index] || highlightBlurb ? 0.8 : 1)
          : colors.grayNumber(bookmarks[index] || highlightBlurb ? 0.8 : 1);
      }}
      onBlur={async (e) => {
        const text = e.currentTarget.innerText;
        dispatch(
          updateTranscriptArray({
            index: index,
            newValue: text,
          })
        );
        // post to the backend with edited transcript
        dispatch(saveTranscriptEdits(supabase));
      }}
      // this is necessary to find this element and
      // later map it to the corresponding summary blurb
      // also to find the elements for dynamic resizing in edit mode
      id={`transcriptBlurbText ${index}`}
      className={`transcriptBlurbText`}
      onClick={(event) => {
        /**
        you can't jump to audio, highlight explanation blurbs, or explain blurbs using professor ossy
        if the blurb is in edit mode or if the blurb is a paid blurb
        **/
        if (paidBlurb || editMode) {
          return;
        }

        // audio player might not be on the page if the
        // user did not opt to save the audio
        const audio = document.getElementById("audio");
        if (audio !== null) {
          audio.currentTime = Number(timestamps[index]);
        }

        // if explanations panel is open, then jump to the corresponding explanation blurb
        if (GPT) {
          const summaryBlurbId = event.currentTarget.className;
          const summaryBlurb = document.getElementById(summaryBlurbId);
          if (summaryBlurb) {
            summaryBlurb.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });

            setHighlightedSummaryBlurbIndex(summaryBlurbId);
            setHighlightedTranscriptBlurbIndex(-1);
            clearTimeout(highlightSummaryBlurbTimeout.current);
            highlightSummaryBlurbTimeout.current = setTimeout(() => {
              logger([LogCategory.DEBUG], "highlight blurb timeout");
              setHighlightedSummaryBlurbIndex(-1);
            }, 1500);
          } else {
            logger([LogCategory.DEBUG], "couldn't find it");
          }
        }

        // if professor ossy panel is open, clicking a blurb selects it and dumpt it in the panel
        if (chat) {
          if (selectedTranscriptBlurbIndices.length === 0) {
            setSelectedTranscriptBlurbIndices([index]);
          }

          // loop through to find where to put the blurb (should be sorted)
          for (let i = 0; i < selectedTranscriptBlurbIndices.length; i++) {
            // index has already been selected, don't add it again
            if (index === selectedTranscriptBlurbIndices[i]) {
              break;
            }

            // found it's place, insert it
            else if (index < selectedTranscriptBlurbIndices[i]) {
              const newIndices = [...selectedTranscriptBlurbIndices];
              newIndices.splice(i, 0, index);
              setSelectedTranscriptBlurbIndices(newIndices);
              break;
            } else if (i === selectedTranscriptBlurbIndices.length - 1) {
              const newIndices = [...selectedTranscriptBlurbIndices];
              setSelectedTranscriptBlurbIndices([...newIndices, index]);
            }
          }
        }
      }}
      defaultValue={text}
      onKeyDown={(event) => {
        if (["Enter", "Escape"].includes(event.key)) {
          event.currentTarget.blur();
        }
      }}
    >
      <span
        style={{
          filter: paidBlurb ? "blur(3px)" : undefined,
          color: darkMode ? "white" : "black",
          justifyContent: "center",
          // display: "flex",
          userSelect: paidBlurb ? "none" : undefined,
        }}
      >
        {text}
      </span>
    </span>
  );
};
export default TranscriptText;
