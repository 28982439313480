import { useEffect, useState } from "react";
import {
  apiEndpoint,
  borderRadius,
  colors,
  logger,
} from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import CheckoutForm from "./CheckoutForm";
import { Button } from "../../../components";
import { initialState } from "../../../data/initialState";
import { checkIfUserUpdates } from "../../../transcriptSlice";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPanel = ({
  setShowCheckout,
  supabase,
  setShowTranscriptUpgradeButton,
  ownerOfTranscript,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const userId = useSelector((state) => state.routes.userId);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ownerOfTranscript && !isPaidUser) {
      console.log("bob reaching here TranscriptPage");
      dispatch(checkIfUserUpdates({ supabase }));
    }
  }, []);

  const appearance = {
    theme: "flat",
    variables: {
      colorText: darkMode ? "#ffffff" : "black",
      fontWeightLight: 500,
      fontWeightNormal: 500,
      fontWeightMedium: 600,
      borderRadius: "4px",
      colorPrimary: "#ffffff",
      colorBackground: darkMode ? colors.gray2 : colors.gray,

      fontWeightBold: 800,
      colorDanger: "red",
    },
    rules: {
      ".Label": { color: "white" },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    })
      .then(async (response) => {
        const customerId = await response.json();
        logger(`got customer`);
        logger(customerId);

        fetch(`${apiEndpoint}/get_subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: customerId,
            userId: userId,
            referralId: (window || {}).promotekit_referral,
          }),
        }).then(async (response) => {
          const data = await response.json();
          logger("got secret");
          logger(data);
          setClientSecret(data["clientSecret"]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <DarkOverlay
        onClick={() => {
          setShowCheckout(false);
          setShowTranscriptUpgradeButton(true);
        }}
        opacity={0.75}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          position: "absolute",
          padding: 24,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: darkMode ? "white" : "black",
        }}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: 28,
            marginBottom: 16,
          }}
        >
          {checkoutCompleted ? "Welcome to Ossy Plus." : "Upgrade to Ossy Plus"}
        </p>

        {!checkoutCompleted && (
          <>
            <p style={{ fontSize: 16, fontWeight: 600, color: "gray" }}>
              1 month of Ossy Plus
            </p>
            <p
              style={{
                fontSize: 16,
                marginBottom: 10,
                fontWeight: 600,
                color: "gray",
              }}
            >
              {initialState.ossyPrice}/month
            </p>
          </>
        )}

        {!checkoutCompleted && clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              redirect={false}
              supabase={supabase}
              setCheckoutCompleted={setCheckoutCompleted}
            />
          </Elements>
        )}
        {checkoutCompleted && (
          <Button
            content={"Continue"}
            style={{ fontSize: 14 }}
            onClick={() => setShowCheckout(false)}
          />
        )}
      </div>
    </>
  );
};

export default CheckoutPanel;
